import { Controller } from "@hotwired/stimulus"
import Litepicker from "litepicker"

export default class extends Controller {
  static targets = ["input", "hidden"]

  connect() {
    this.initializeLitepicker()
  }

  initializeLitepicker() {
    new Litepicker({
      element: this.inputTarget,
      format: "D MMMM, YYYY",
      allowRepick: true,
      autoApply: true.valueOf,
      setup: (picker) => {
        picker.on("selected", (date1, _date2) => {
          this.hiddenTarget.value = date1.format("YYYY-MM-DD");
        })
      }
    })
  }
}
