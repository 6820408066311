import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => this.close(), 10000);
  }

  close(e) {
    this.element.classList.add("hidden")
  }
}
